export const TRAIT_PRIORITY_RANGE_MIN_RANGE = 1;
export const TRAIT_PRIORITY_RANGE_MAX_RANGE = 10;

export const OPTIMIZELY_PROJECT_NAME = 'BEES Engage';

export const VENDOR_LOCAL_STORAGE = 'userInfo';

export const CSV_ID_HEADER = 'POC_ID';

export const QUERY_NAME = 'origin';

import Loader from 'components/Loader/Loader';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DefaultLayout from './DefaultLayout/DefaultLayout';

const AudiencePage = lazy(() => import('pages/AudiencePage/AudiencePage'));

const DashboardPage = lazy(() => import('pages/DashboardPage/DashboardPage'));

const RequireOrigin = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const origin = searchParams.get('origin');
  //@ts-ignore
  if (!origin && !window.Cypress) {
    navigate('/error/403');
  }

  return children;
};

const Router = () => {
  return (
    <Suspense fallback={<Loader wrapperSize="page" />}>
      <BrowserRouter>
        <RequireOrigin>
          <Routes>
            <Route path="audiences" element={<DefaultLayout />}>
              <Route index element={<DashboardPage />} />
              <Route path="new-audience" element={<AudiencePage />} />
              <Route path="audience/:id" element={<AudiencePage />} />
            </Route>
          </Routes>
        </RequireOrigin>
      </BrowserRouter>
    </Suspense>
  );
};
export default Router;
